'use client';

import { RssFeed } from '@/types/sermons';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import Parser from 'rss-parser';

export default function FrontPageRss({
  rssFeed,
  url,
  title,
}: {
  rssFeed?: RssFeed;
  url?: string;
  title?: string;
}) {
  const [feed, setFeed] = useState<RssFeed | undefined>(rssFeed);

  useEffect(() => {
    if (url) {
      const oldFeed: RssFeed | null = JSON.parse(
        localStorage.getItem(`rss:${url}`) || 'null',
      );
      const expiry = moment(localStorage.getItem(`rss:expiry:${url}`));
      if (oldFeed && moment().isBefore(expiry)) {
        setFeed(oldFeed);
        return;
      }
      (async () => {
        const parser = new Parser();

        const newFeed = (await parser.parseURL(url)) as RssFeed;
        setFeed(newFeed);
        if (newFeed) {
          localStorage.setItem(`rss:${url}`, JSON.stringify(newFeed));
          localStorage.setItem(
            `rss:expiry:${url}`,
            moment().add(6, 'hours').toDate().toISOString(),
          );
        }
      })().catch(console.error);
    }
  }, [url]);

  return (
    <>
      {feed && feed.items && (
        <div className='border-b border-l-0 border-gray-200 p-4 last:border-b-0 lg:even:border-l [&:nth-last-child(2)]:lg:border-b-0 [&:nth-last-child(2)]:even:lg:border-b'>
          <div className='mb-4 font-bold'>{title || feed.title}</div>
          <ul className='ml-4 list-disc text-sm'>
            {feed.items.slice(0, 10).map((item) => (
              <li key={`${item.title}-${item.guid}`}>
                <a
                  className='cursor-pointer font-serif italic hover:underline'
                  href={item.link}
                  rel='noopener noreferrer nofollow'
                  target='_blank'
                >
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}
