'use client';

import { findMainPreviewContents } from '@/lib/helpers';
import { MediaContent } from '@/types/sermons';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import slugify from 'slugify';
import VideoPlayer from '../molecules/video-player';

export default function FeaturedMedia({ media }: { media: MediaContent[] }) {
  return (
    <>
      <div className='mb-2 font-bold'>
        Featured Media &middot;{' '}
        <Link
          className='italic text-sermons-dark hover:underline'
          href='/search/keyword?term=&type=Media'
        >
          see all
        </Link>
      </div>
      <div className='grid grid-cols-1 gap-4 lg:grid-cols-3'>
        {media.map((media) => {
          const mainPreview = findMainPreviewContents(
            media.gracewayPreviewContents,
          );

          return (
            <div key={media.id}>
              <div className='relative bg-black pt-[56.25%]'>
                {mainPreview?.typeName === 'video' && (
                  <>
                    <Link
                      className='absolute left-0 top-0 z-40 block w-full pt-[56.25%]'
                      href={`/media/${slugify(media.name).toLowerCase()}/${
                        media.id
                      }`}
                    ></Link>
                    <VideoPlayer
                      className={classNames('w-full absolute left-0', {
                        'top-0': media.productType !== 'Ultra-Wides',
                        'top-[25%]': media.productType === 'Ultra-Wides',
                      })}
                      width={672}
                      height={378}
                      src={mainPreview?.asset || ''}
                      loop
                      autoPlay
                      muted
                      playsInline
                    />
                  </>
                )}
                {mainPreview?.typeName === 'main_image' && (
                  <Link
                    className='block'
                    href={`/media/${slugify(media.name).toLowerCase()}/${
                      media.id
                    }`}
                  >
                    <Image
                      className={classNames('w-full absolute left-0', {
                        'top-0': media.productType !== 'Ultra-Wides',
                        'top-[25%]': media.productType === 'Ultra-Wides',
                      })}
                      width={672}
                      height={378}
                      src={mainPreview?.asset || ''}
                      alt={media.description || media.name}
                    />
                  </Link>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
